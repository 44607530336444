<template>
  <div>
    <b-card title="Aliff Reccomendations">
      <b-row>
        <b-col cols="12" md="6" lg="4" v-for="listing in recommdations" :key="listing.id">
          <b-card :img-src="FILESURL + listing.aci_url" img-alt="listing.title" img-top
            style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important">
            <div>
              <div class="d-flex justify-content-start">
                <b-card-title class="font-weight-bold">{{
                  listing.acc_name
                  }}</b-card-title>
              </div>
              <b-card-text>
                <div class="mt-1">
                  <div>
                    <p class="mb-1">
                      <feather-icon icon="MapPinIcon" stroke="#000" style="margin-right: 8px" size="20" />{{
                      listing.acc_address }},<span style="font-weight: 600">{{ listing.city_name }}</span>
                    </p>
                  </div>
                  <div>
                    <feather-icon icon="BoxIcon" stroke="#000" style="margin-right: 8px" size="20" />
                    {{ listing.acf_names }}
                  </div>
                </div>
                <!-- <div
                  class="d-flex justify-content-between align-items-center mt-2"
                >
                  <div class="d-flex align-items-center">
                    <h4 class="font-weight-bold m-0">
                      ${{ listing.acc_price_per_month }}
                    </h4>
                    <span>/month</span>
                  </div>
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="openRecommendModal(listing)"
                    v-if="['services'].includes(user_type)"
                    >Recommend</b-button
                  >
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="shortListAccommodation(listing)"
                    v-if="
                      ['student'].includes(user_type) &&
                      (listing.is_favourite === 'N' ||
                        listing.is_favourite === null)
                    "
                    >Shortlist</b-button
                  >
                  <b-button
                    size="sm"
                    variant="secondary"
                    @click="UnshortListAccommodation(listing)"
                    v-if="
                      ['student'].includes(user_type) &&
                      listing.is_favourite === 'Y'
                    "
                    >UnShortlist</b-button
                  >
                </div> -->
              </b-card-text>
            </div>
          </b-card>
        </b-col>

        <div v-if="recommdations.length === 0" class="w-100">
          <h5 class="text-primary ml-2">No Recommended Accommodation</h5>
        </div>
      </b-row>
    </b-card>

    <b-card title="Favourites">
      <b-row>
        <b-col cols="12" md="6" lg="4" v-for="listing in listings" :key="listing.id">
          <b-card :img-src="FILESURL + listing.aci_url" img-alt="listing.title" img-top
            style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important">
            <div>
              <div class="d-flex justify-content-start">
                <b-card-title class="font-weight-bold">{{
                  listing.acc_name
                  }}</b-card-title>
              </div>
              <b-card-text>
                <div class="mt-1">
                  <div>
                    <p class="mb-1">
                      <feather-icon icon="MapPinIcon" stroke="#000" style="margin-right: 8px" size="20" />{{
                      listing.acc_address }},<span style="font-weight: 600">{{ listing.city_name }}</span>
                    </p>
                  </div>
                  <div>
                    <feather-icon icon="BoxIcon" stroke="#000" style="margin-right: 8px" size="20" />
                    {{ listing.acf_names }}
                  </div>
                </div>
                <!-- <div
                  class="d-flex justify-content-between align-items-center mt-2"
                >
                  <div class="d-flex align-items-center">
                    <h4 class="font-weight-bold m-0">
                      ${{ listing.acc_price_per_month }}
                    </h4>
                    <span>/month</span>
                  </div>
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="openRecommendModal(listing)"
                    v-if="['services'].includes(user_type)"
                    >Recommend</b-button
                  >
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="shortListAccommodation(listing)"
                    v-if="
                      ['student'].includes(user_type) &&
                      (listing.is_favourite === 'N' ||
                        listing.is_favourite === null)
                    "
                    >Shortlist</b-button
                  >
                  <b-button
                    size="sm"
                    variant="secondary"
                    @click="UnshortListAccommodation(listing)"
                    v-if="
                      ['student'].includes(user_type) &&
                      listing.is_favourite === 'Y'
                    "
                    >UnShortlist</b-button
                  >
                </div> -->
              </b-card-text>
            </div>
          </b-card>
        </b-col>

        <div v-if="listings.length === 0" class="w-100">
          <h5 class="text-primary ml-2">No Shortlisted Accommodation</h5>
        </div>
      </b-row>
    </b-card>


  </div>
</template>

<script>
import StudentServices from "@/apiServices/StudentServices";
import { FILESURL } from "@/config";
import store from "@/store";
import { BButton, BCard, BCardText, BCardTitle, BCol, BRow } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BCardTitle,
    BCard
  },
  data() {
    return {
      listings: [],
      recommdations: [],
      FILESURL
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },
  watch: {
    user_type: {
      immediate: true,
      handler() {
        if (this.user_type && ['student'].includes(this.user_type)) {
          this.shortListedAccommodation()
        }
      },
    }
  },
  methods: {
    async shortListedAccommodation() {
      try {
        const res = await StudentServices.getAccommodationFavourites();
        if (res.data.status) {
          this.listings = res.data.data
        }
      } catch (error) {
        console.error("Error in getting shortlisted accommodation:", error);
      }
    },
    async recommendedAccommodation() {
      try {
        const res = await StudentServices.getAccommodationRecommendations();
        if (res.data.status) {
          this.recommdations = res.data.data
        }
      } catch (error) {
        console.error("Error in getting recommended accommodation:", error);
      }
    }
  },
  beforeMount() {

  },
  mounted() {
    if (this.user_type && ['student'].includes(this.user_type)) {
      this.shortListedAccommodation();
      this.recommendedAccommodation();
    }
  },
};
</script>
