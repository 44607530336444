<template>
  <div>
    <b-card>
      <b-modal
        :no-close-on-backdrop="true"
        id="acc_reccomend-modal-id"
        centered
        title="Select Students"
        hide-footer
      >
        <br />
        <b-button
          @click="
            recommendToStudents(
              recommend_accommodation.acc_id,
              students_selected
            )
          "
          variant="success"
          size="sm"
        >
          RECOMMEND
        </b-button>
        <br />
        <br />
        {{
          this.recommend_accommodation
            ? this.recommend_accommodation.acc_name
            : ""
        }}
        <br />
        <v-select
          multiple
          :reduce="(student) => student.student_user_id"
          label="user_name"
          v-model="students_selected"
          :options="assigned_students"
          :closeOnSelect="false"
        >
          <template #selected-option="data">
            <b-avatar
              :src="FILESURL + data.user_profile_image"
              size="16"
              class="align-middle mr-50"
            />
            <span> {{ data.user_name }}</span>
          </template>
          <template #option="data">
            <b-avatar
              :src="FILESURL + data.user_profile_image"
              size="16"
              class="align-middle mr-50"
            />
            <span> {{ data.user_name }}</span>
          </template>
        </v-select>
      </b-modal>

      <b-tabs v-model="activeTab">
        <!-- <b-tab title="On Campus" v-if="showOnCampus">
          <b-card>
            <div class="filters-section">
              <b-row>
                <b-col cols="12">
                  <b-form-input
                    v-model="filters.search"
                    placeholder="Search by Country,City or University"
                    @input="onSearch"
                  ></b-form-input>
                </b-col>
              </b-row>
            </div>
            <div>
              <div>
                <b-row class="py-4">
                  <b-col cols="3" class="">
                    <b-card>
                      <b-card-title>Filters</b-card-title>
                      <b-form-group label="Property Type">
                        <b-form-checkbox-group
                          class="d-flex flex-column"
                          v-model="filters.type"
                          :options="propertyOptions"
                          @input="onSearch"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <b-form-group label="Amenities">
                        <b-form-checkbox-group
                          class="d-flex flex-column"
                          v-model="filters.facilities"
                          :options="amenityOptions"
                          @input="onSearch"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <b-form-group label="Currency">
                        <v-select
                          multiple
                          :reduce="(currency) => currency.code"
                          label="code"
                          v-model="filters.currency"
                          :options="currencies"
                          @input="onSearch"
                        ></v-select>
                      </b-form-group>
                    </b-card>
                  </b-col>

                  <b-col>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        mb-3
                      "
                    >
                      <h2>{{ totalListings }}+ stays</h2>
                      <div class="d-flex">
                        <b-form-select
                          v-model="filters.sort"
                          :options="[
                            { value: null, text: 'Sort by', disabled: true },
                            ...sortOptions,
                          ]"
                          class="mr-2"
                          @input="onSearch"
                          clearable
                        ></b-form-select>
                      </div>
                    </div>

                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                        lg="4"
                        v-for="listing in listings"
                        :key="listing.id"
                      >
                        <b-card
                          :img-src="FILESURL + listing.aci_url"
                          img-alt="listing.title"
                          img-top
                          style="
                            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
                          "
                        >
                          <div>
                            <div class="d-flex justify-content-start">
                              <b-card-title class="font-weight-bold">{{
                                listing.acc_name
                              }}</b-card-title>
                            </div>
                            <b-card-text>
                              <div class="mt-1">
                                <div>
                                  <p class="mb-1">
                                    <feather-icon
                                      icon="MapPinIcon"
                                      stroke="#000"
                                      style="margin-right: 8px"
                                      size="20"
                                    />{{ listing.acc_address }},<span
                                      style="font-weight: 600"
                                      >{{ listing.city_name }}</span
                                    >
                                  </p>
                                </div>
                                <div>
                                  <feather-icon
                                    icon="BoxIcon"
                                    stroke="#000"
                                    style="margin-right: 8px"
                                    size="20"
                                  />
                                  {{ listing.acf_names }}
                                </div>
                              </div>
                              <div
                                class="
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  mt-2
                                "
                              >
                                <div class="d-flex align-items-center">
                                  <h4 class="font-weight-bold m-0">
                                    ${{ listing.acc_price_per_month }}
                                  </h4>
                                  <span>/month</span>
                                </div>
                                <b-button
                                  size="sm"
                                  variant="primary"
                                  @click="openRecommendModal(listing)"
                                  v-if="['services'].includes(user_type)"
                                  >Recommend</b-button
                                >
                                <b-button
                                  size="sm"
                                  variant="primary"
                                  @click="shortListAccommodation(listing)"
                                  v-if="
                                    ['student'].includes(user_type) &&
                                    (listing.is_favourite === 'N' ||
                                      listing.is_favourite === null)
                                  "
                                  >Shortlist</b-button
                                >
                                <b-button
                                  size="sm"
                                  variant="secondary"
                                  @click="UnshortListAccommodation(listing)"
                                  v-if="
                                    ['student'].includes(user_type) &&
                                    listing.is_favourite === 'Y'
                                  "
                                  >UnShortlist</b-button
                                >
                              </div>
                            </b-card-text>
                          </div>
                        </b-card>
                      </b-col>
                    </b-row>

                    <b-pagination
                      v-model="pagination.currentPage"
                      :total-rows="totalListings"
                      :per-page="pagination.perPage"
                      @change="onFilterChange"
                      class="my-3"
                      align="center"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-tab> -->
        <b-tab title="Off Campus">
          <div>
            <Accommodations />
          </div>
        </b-tab>

        <b-tab
          title="Recommendations & Favourites"
          v-if="['student'].includes(user_type)"
        >
          <ReccAndFavAccomodations />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BNavbar,
  BContainer,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BFormInput,
  BFormDatepicker,
  BButton,
  BFormGroup,
  BFormRange,
  BFormCheckboxGroup,
  BFormSelect,
  BPagination,
  BCardText,
  BCardTitle,
  BCardBody,
  BAvatar,
} from "bootstrap-vue";
import Accommodations from "./Accomodations.vue";
import HomeServices from "@/apiServices/HomeServices";
import vSelect from "vue-select";
import { FILESURL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import CustomCheckbox from "../University Search/Search/helper/CustomCheckbox.vue";
import StudentServices from "@/apiServices/StudentServices";
import ReccAndFavAccomodations from "./ReccAndFavAccomodations.vue";

export default {
  name: "AccommodationWrapper",
  components: {
    BTab,
    BCard,
    BTabs,
    BRow,
    BCol,
    BNavbar,
    BContainer,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BFormInput,
    BFormDatepicker,
    BButton,
    BFormGroup,
    BFormRange,
    BFormCheckboxGroup,
    BFormSelect,
    BPagination,
    Accommodations,
    vSelect,
    BCardText,
    BCardTitle,
    BCardBody,
    BFormInput,
    BAvatar,
    ReccAndFavAccomodations,
  },
  data() {
    return {
      activeTab: 0,
      universities: [],
      accommodations: [],
      countries: [],
      states: [],
      cities: [],
      filters: {
        // university_id: null,
        // country_id: null,
        // state_id: null,
        // city_id: null,
        search: null,
        type: [],
        facilities: [],
        priceRange: null,
        sort: null,
        currency: null,
      },
      location: "",
      date: null,
      guests: 1,
      priceRange: 500,
      propertyType: [],
      amenities: [],
      sortBy: "Recommended",
      pagination: {
        currentPage: 1,
        perPage: 10,
      },
      minPrice: 0,
      maxPrice: 1000,
      priceRange: [0, 200],
      FILESURL,
      totalListings: null,
      propertyOptions: [
        { text: "Hostel", value: "hostel", key: "hostel" },
        { text: "Apartment", value: "apartment", key: "apartment" },
        {
          text: "Shared Housing",
          value: "shared_housing",
          key: "shared_housing",
        },
        { text: "Homestay", value: "homestay", key: "homestay" },
        { text: "Hotel", value: "hotel", key: "hotel" },
        { text: "Others", value: "others", key: "others" },
      ],
      sortOptions: [
        // { text: "Sort by: Recommended", value: "Recommended" },
        { text: "Price: high to low", value: "asc" },
        { text: "Price: low to high", value: "desc" },
        // { text: "Rating", value: "Rating" },
      ],
      amenityOptions: [
        { text: "Parking", value: "parking" },
        { text: "Gym", value: "gym" },
        { text: "Swimming Pool", value: "swimming_pool" },
        { text: "Laundry", value: "laundry" },
      ],
      debounce: null,
      assigned_students: [],
      students_selected: [],
      recommend_accommodation: null,
      listings: [],
      currencies: [],
      selected_currencies: [],
      showOnCampus: true,
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },
  methods: {
    onFilterChange() {
      this.$nextTick(() => {
        this.getAllAccommodations();
      });
    },
    onSearch() {
      if (this.debounce) {
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onFilterChange();
      }, 1000);
    },
    async getAllUniversities() {
      try {
        const response = await HomeServices.getAllUniversities();
        if (response.data.status) {
          this.universities = response.data.data;
        }
      } catch (error) {
        console.log("Error in getAllUniversities", error);
      }
    },
    async getAllCountries() {
      try {
        const response = await HomeServices.getAllCountries();
        if (response.data.status) {
          this.countries = response.data.data;
        }
      } catch (error) {
        console.log("Error in getAllCountries", error);
      }
    },
    async getAllStates() {
      try {
        const response = await HomeServices.getAllStates();
        if (response.data.status) {
          this.states = response.data.data;
        }
      } catch (error) {
        console.log("Error in getAllStates", error);
      }
    },
    async getAllCities() {
      try {
        const response = await HomeServices.getAllCities();
        if (response.data.status) {
          this.cities = response.data.data;
        }
      } catch (error) {
        console.log("Error in getAllCities", error);
      }
    },
    async getAllAccommodations() {
      try {
        let response;

        if (this.user_type && this.user_type === "student") {
          response = await StudentServices.getAllAccommodations({
            ...this.filters,
            currentPage: this.pagination.currentPage,
            perPage: this.pagination.perPage,
          });
        } else {
          response = await HomeServices.getAllAccommodations({
            ...this.filters,
            currentPage: this.pagination.currentPage,
            perPage: this.pagination.perPage,
          });
        }

        if (response.data.status) {
          this.listings = response.data.data.data;
          this.totalListings = response.data.data.total;
        }
      } catch (error) {
        console.log("Error in getAllAccommodations", error);
      }
    },
    async getStudentForAccomodation() {
      try {
        const response = await CommonServices.getStudentForAccomodation();
        if (response.data.status) {
          this.assigned_students = response.data.data;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error fetching Students",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error getting assigned students ", error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error fetching Students",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    async recommendToStudents(acc_id, students_selected) {
      try {
        console.log("Accommodation", acc_id, students_selected);
        const payload = {
          acc_id,
          student_user_ids: students_selected,
        };
        const res = await CommonServices.recommendAccomodationToStudents(
          payload
        );
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Success",
              icon: "Check",
              variant: "success",
            },
          });
          this.$bvModal.hide("acc_reccomend-modal-id");
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Error",
              icon: "Check",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error recommending to students", error);
      }
    },
    openRecommendModal(listing) {
      this.recommend_accommodation = listing;
      this.$bvModal.show("acc_reccomend-modal-id");
    },
    async getAllCurrency() {
      try {
        const res = await CommonServices.getAllFlywireCurrencies();
        if (res.data.status) {
          this.currencies = res.data.data;
        }
      } catch (error) {
        console.error("Error in getAllCurrency", error);
      }
    },
    async shortListAccommodation(acc) {
      try {
        const acc_id = acc.acc_id;
        const res = await StudentServices.addAccommodationToFavourite(acc_id);
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Success",
              icon: "Check",
              variant: "success",
            },
          });
          this.getAllAccommodations();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Error",
              icon: "Check",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error in shortListAccommodation", error);
      }
    },

    async UnshortListAccommodation(acc) {
      try {
        const acc_id = acc.acc_id;
        const res = await StudentServices.removeAccommodationToFavourite(
          acc_id
        );
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Success",
              icon: "Check",
              variant: "success",
            },
          });
          this.getAllAccommodations();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Error",
              icon: "Check",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error in UnshortListAccommodation", error);
      }
    },
    async showOnCampusAccommodation() {
      try {
        const res = await StudentServices.showOnCampus();
        if (res.data.status) {
          this.showOnCampus = res.data.data;
        }
      } catch (error) {
        console.error("Error in showOnCampusAccommodation", error);
      }
    },
  },
  beforeMount() {
    this.getAllUniversities();
    this.getAllCountries();
    this.getAllStates();
    this.getAllCities();
    // this.getAllCurrency();
  },
  mounted() {
    if (this.user_type && ["student"].includes(this.user_type)) {
      this.showOnCampusAccommodation();
    }
    if (this.user_type && ["services"].includes(this.user_type)) {
      this.getStudentForAccomodation();
      this.showOnCampus = true;
    }
    this.getAllAccommodations();
  },
};
</script>

<style scoped>
.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
</style>
