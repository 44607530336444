<template>
  <b-card>
    <b-table :items="services" :fields="fields">
      <template #cell(show)="row">
        <feather-icon
          icon="PlusCircleIcon"
          size="20"
          v-if="!row.detailsShowing"
          @click="row.toggleDetails"
        />
        <feather-icon
          icon="MinusCircleIcon"
          size="20"
          v-if="row.detailsShowing"
          @click="row.toggleDetails"
        />
      </template>

      <template #row-details="row">
        <b-tabs v-model="row.item.active_tab_index">
          <b-tab title="Content">
            <b-card>
              <b-row
                style="gap: 20px 0"
                v-if="row.item.content && row.item.content.length > 0"
              >
                <b-col
                  v-for="(c, index) in row.item.content"
                  :key="'c_' + index"
                  cols="12"
                  md="3"
                >
                  <span>{{ c.content_name }}</span>
                  <br />
                  <div v-if="c.uploaded_file_link">
                    <embed
                      :src="FILESURL + c.uploaded_file_link"
                      width="100%"
                      height="200px"
                    />
                    <br />
                    <span>
                      <a target="_blank" :href="FILESURL + c.uploaded_file_link"
                        >open</a
                      >
                    </span>
                  </div>

                  <div v-if="c.file_link">
                    <embed :src="c.file_link" width="100%" height="200px" />
                    <br />
                    <span>
                      <a target="_blank" :href="c.file_link">open</a>
                    </span>
                  </div>

                  <div v-if="c.youtube_link">
                    <div v-html="c.youtube_link"></div>
                  </div>

                  <div
                    v-if="row.item.plan_id === 6 && c.sat_report_link"
                    style="width: 270px"
                  >
                    <embed
                      v-if="c.sat_report_link"
                      :src="generatePdfBlobUrl(c.sat_report_link)"
                      width="100%"
                      height="200px"
                      type="application/pdf"
                    />
                    <br />
                    <div class="d-flex justify-content-between">
                      <span>
                        <a
                          v-if="c.sat_report_link"
                          target="_blank"
                          :href="generatePdfBlobUrl(c.sat_report_link)"
                          >open</a
                        >
                      </span>
                      <span>
                        <a
                          v-if="c.sat_report_link"
                          download="sat_report"
                          :href="generatePdfBlobUrl(c.sat_report_link)"
                          >download</a
                        >
                      </span>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div v-else>No Content Available.</div>
            </b-card>
          </b-tab>
          <b-tab title="Features">
            <b-table hover :items="row.item.features" :fields="featureFields">
              <template #cell(feature_name)="data">
                <span class="font-weight-bold">{{
                  data.item.feature_name
                }}</span>
              </template>
              <template #cell(progress)="data">
                <div>
                  <b-badge
                    class="ml-1 mr-5"
                    :variant="
                      isFeatureCompleted(data.item.feature_id)
                        ? 'success'
                        : 'warning'
                    "
                  >
                    {{
                      isFeatureCompleted(data.item.feature_id)
                        ? "Complete"
                        : "Pending"
                    }}
                  </b-badge>
                </div>
              </template>
              <template #cell(completion_date)="data">
                <div>
                  <div
                    v-if="getCompletedFeatureDate(data.item.feature_id)"
                    class="rounded-lg shadow-sm"
                    style="padding: 2px 8px"
                  >
                    {{
                      moment(
                        getCompletedFeatureDate(data.item.feature_id)
                      ).format("DD MMM, YYYY HH:mm:ss")
                    }}
                  </div>
                  <div v-else>
                    <b-badge variant="light" style="min-width: 100px"
                      >-
                    </b-badge>
                  </div>
                </div>
              </template>
              <template #cell(documents)="data">
                <div
                  class="d-flex justify-content-start"
                  style="min-width: 150px"
                >
                  <a
                    v-if="data.item.sfd_file_path"
                    :href="FILESURL + data.item.sfd_file_path"
                    target="_blank"
                    class="pr-4"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="20"
                      class="cursor-pointer"
                    />
                  </a>

                  <feather-icon
                    icon="FilePlusIcon"
                    size="20"
                    class="cursor-pointer"
                    @click="openQuestionModal"
                    v-else-if="data.item.feature_id === 99 && showFormIcon"
                  />
                  <feather-icon
                    icon="EyeIcon"
                    size="20"
                    class="cursor-pointer"
                    @click="$bvModal.show('modal-questions-view')"
                    v-else-if="data.item.feature_id === 99 && !showFormIcon"
                  />

                  <feather-icon
                    icon="MinusIcon"
                    size="20"
                    class="cursor-pointer"
                    v-else
                  />
                </div>
              </template>
              <template #cell(remarks)="data">
                <div class="d-flex justify-content-start">
                  <b-popover
                    v-if="
                      data.item.sfd_remark && data.item.sfd_remark.length > 0
                    "
                    :content="data.item.sfd_remark"
                    triggers="hover"
                    placement="top"
                    target="remark"
                  >
                    <h6>{{ data.item.sfd_remark }}</h6>
                  </b-popover>
                  <!-- <h6 v-else-if="data.item.sfd_remark" id="remark">{{ data.item.sfd_remark }}</h6> -->
                  <span v-if="data.item.sfd_remark">
                    <feather-icon
                      icon="InfoIcon"
                      size="20"
                      class="cursor-pointer"
                      id="remark"
                    />
                  </span>
                  <h4 v-else>
                    <feather-icon
                      icon="MinusIcon"
                      size="20"
                      class="cursor-pointer"
                      @click="getFeatureDocById(data.item.sfd_id)"
                    />
                  </h4>
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab
            title="Part Payments"
            v-if="row.item.partPayments && row.item.partPayments.length > 0"
          >
            <div class="shadow-xl rounded-lg border" style="overflow: hidden">
              <div class="d-flex border-bottom bg-white">
                <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Part</h4>
                <h4 class="my-1" style="min-width: 200px">Price</h4>
                <h4 class="mt-1" style="min-width: 200px">Status</h4>
                <h4 class="mt-1" style="min-width: 200px">Payment Date</h4>
              </div>
              <div class="bg-fafafa overflow-hidden">
                <div
                  class="d-flex pt-1 border-bottom pb-1 ml-1"
                  v-for="(part, index) in row.item.partPayments"
                  :key="'part_' + index"
                >
                  <span class="font-weight-bold" style="min-width: 500px"
                    >Part {{ index + 1 }}</span
                  >
                  <div style="min-width: 200px">
                    {{ part.spp_price }} {{ part.spp_currency }}
                  </div>
                  <div style="min-width: 200px">
                    <b-badge
                      :variant="part.is_paid === 'Y' ? 'success' : 'warning'"
                    >
                      {{ part.is_paid === "Y" ? "Paid" : "Pending" }}
                    </b-badge>
                  </div>
                  <div v-if="part.is_paid === 'Y'" style="padding: 2px 8px">
                    {{
                      moment(part.updated_at).format("DD MMM, YYYY HH:mm:ss")
                    }}
                  </div>
                  <div
                    v-else-if="
                      index ===
                      row.item.partPayments.filter((p) => p.is_paid === 'Y')
                        .length
                    "
                  >
                    <b-badge variant="primary">Pay Now</b-badge>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </template>

      <template #cell(buy_date)="row">
        {{ moment(row.item.buy_date).format("DD MMM, YYYY - HH:mm:ss") }}
      </template>

      <template #cell(amount)="row">
        {{
          row.item.partPayments.length > 0
            ? `${row.item.partPayments
                .filter((p) => p.is_paid === "Y")
                .reduce((acc, p) => acc + p.spp_price, 0)}
        ${row.item.partPayments[0].spp_currency || ""}`
            : row.item.amount
        }}
      </template>
    </b-table>

    <div>
      <!-- <b-modal
        id="modal-questions"
        title="SOP Questions"
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
      >
        <div
          v-for="(question, index) in questions"
          :key="question.sq_id"
          class="mb-2"
        >
          <label :for="'question-' + index" style="font-size: 1.2em">{{
            question.sq_question
          }}</label>
          <b-form-textarea
            :id="'question-' + index"
            :value="getAnswer(question.sq_id)"
            @input="updateAnswer(question.sq_id, $event)"
            :rows="2"
          ></b-form-textarea>
        </div>

        <div class="d-flex justify-content-end">
          <b-button variant="success" @click="submitAnswers"> Submit </b-button>
        </div>
      </b-modal> -->

      <b-modal
        id="modal-questions-view"
        title="SOP Questions"
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
      >
        <div
          v-for="(item, index) in paginatedQuestionsWithAnswers"
          :key="item.sqa_id"
          class="mb-3"
        >
          <!-- Main question -->
          <label
            :for="'view-question-' + index"
            class="d-block font-weight-bold"
          >
            {{ index + 1 }}. {{ item.sq_question }}
          </label>
          <b-form-textarea
            :id="'view-question-' + index"
            :value="item.sqa_answer"
            readonly
            rows="2"
            class="mb-2"
          ></b-form-textarea>

          <!-- Subquestions -->
          <div
            v-if="item.sub_questions && item.sub_questions.length"
            class="pl-3"
          >
            <div
              v-for="(subq, subIndex) in item.sub_questions"
              :key="subq.ssq_id"
              class="mb-2"
            >
              <label
                :for="`view-sub-${subq.ssq_id}`"
                class="font-weight-bold small"
              >
                {{ String.fromCharCode(97 + subIndex) }})
                {{ subq.ssq_question }}
              </label>
              <b-form-textarea
                :id="`view-sub-${subq.ssq_id}`"
                :value="subq.sqa_sub_answer"
                readonly
                rows="2"
                class="mb-1"
              ></b-form-textarea>
            </div>
          </div>
        </div>
        <!-- Pagination Controls -->
        <div class="d-flex justify-content-between mt-2 align-items-center">
          <b-button
            variant="outline-primary"
            @click="prevAnswerPage"
            size="sm"
            :disabled="answer_currentPage === 0"
          >
            Previous
          </b-button>
          <span>Page {{ answer_currentPage + 1 }} / {{ totalPagesWithAnswers }}</span>
          <b-button
            variant="outline-primary"
            @click="nextAnswerPage"
            size="sm"
            v-if="answer_currentPage < totalPagesWithAnswers - 1"
          >
            Next
          </b-button>
        </div>
      </b-modal>

      <b-modal
        id="modal-questions"
        title="SOP Questions"
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
      >
        <div
          v-for="(question, index) in paginatedQuestions"
          :key="question.sq_id"
          class="mb-3"
        >
          <!-- Main question -->
          <label
            :for="'question-' + question.sq_id"
            class="d-block font-weight-bold"
          >
            {{ index + 1 }}. {{ question.sq_question }}
          </label>
          <b-form-textarea
            :id="'question-' + question.sq_id"
            v-model="answers[`main_${question.sq_id}`]"
            rows="2"
            class="mb-2"
          ></b-form-textarea>

          <!-- Subquestions -->
          <div
            v-if="question.subquestions && question.subquestions.length"
            class="pl-3"
          >
            <div
              v-for="(subq, subIndex) in question.subquestions"
              :key="subq.ssq_id"
              class="mb-2"
            >
              <label :for="`sub_${subq.ssq_id}`" class="font-weight-bold small">
                {{ String.fromCharCode(97 + subIndex) }})
                {{ subq.ssq_question }}
              </label>
              <b-form-input
                :id="`sub_${subq.ssq_id}`"
                v-model="answers[`sub_${subq.ssq_id}`]"
                class="mb-1"
              ></b-form-input>
            </div>
          </div>
        </div>

        <!-- Pagination Controls -->
        <div class="d-flex justify-content-between mt-2 align-items-center">
          <b-button
            variant="outline-primary"
            @click="prevPage"
            size="sm"
            :disabled="currentPage === 0"
          >
            Previous
          </b-button>
          <span>Page {{ currentPage + 1 }} / {{ totalPages }}</span>
          <b-button
            v-if="currentPage === totalPages - 1"
            variant="success"
            @click="submitAnswers"
            size="sm"
          >
            Submit
          </b-button>
          <b-button
            v-else
            variant="outline-primary"
            @click="nextPage"
            size="sm"
          >
            Next
          </b-button>
        </div>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import {
  BCardCode,
  BCard,
  BTable,
  BRow,
  BCol,
  BTab,
  BTabs,
  BBadge,
  BFormTextarea,
  BModal,
  BButton,
  BPopover,
  BFormInput,
  BForm,
} from "bootstrap-vue";
import moment from "moment";
import { FILESURL } from "@/config";
import StudentServices from "@/apiServices/StudentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BCard,
    BTable,
    BRow,
    BCol,
    BTab,
    BTabs,
    BBadge,
    BFormTextarea,
    BModal,
    BButton,
    BPopover,
    BFormInput,
    BForm,
  },
  data() {
    return {
      services: [],
      completed_features: [],
      fields: ["show", "service_name", "plan_name", "amount", "buy_date"],
      FILESURL,
      sat_availed: false,
      questions: [],
      answers: {},
      showFormIcon: true,
      question_with_answers: [],
      featureFields: [
        { key: "feature_name", label: "Features", sortable: true },
        { key: "progress", label: "Progress", sortable: false },
        { key: "completion_date", label: "Completion Date", sortable: true },
        { key: "documents", label: "Documents", sortable: false },
        { key: "remarks", label: "Remarks", sortable: false },
      ],

      showModal: false,
      currentPage: 0,
      perPage: 2, 

      answer_currentPage: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.questions.length / this.perPage);
    },
    totalPagesWithAnswers() {
      return Math.ceil(this.question_with_answers.length / this.perPage);
    },
    paginatedQuestions() {
      const start = this.currentPage * this.perPage;
      return this.questions.slice(start, start + this.perPage);
    },
    paginatedQuestionsWithAnswers() {
      const start = this.answer_currentPage * this.perPage;
      return this.question_with_answers.slice(start, start + this.perPage);
    },
  },

  methods: {
    prevPage() {
      if (this.currentPage > 0) this.currentPage--;

    },
    nextPage() {
      if (this.currentPage < this.totalPages - 1) this.currentPage++;
    },
    prevAnswerPage() {
      if (this.answer_currentPage > 0) this.answer_currentPage--;
    },
    nextAnswerPage() {
      if (this.answer_currentPage < this.totalPagesWithAnswers - 1)
        this.answer_currentPage++;
    },
    submitAnswers() {
      console.log("Submitted Answers:", this.answers);
      this.showModal = false;
    },
    openQuestionModal() {
      this.getSOPQuestions();
      this.$bvModal.show("modal-questions");
    },
    async getSOPAnswers() {
      try {
        const res = await StudentServices.getSOPAnswers();
        if (res.data.status) {
          if (res.data.data.length > 0) {
            // Check if any answer exists (not null)
            const hasAnyAnswer = res.data.data.some(
              (answer) =>
                answer.sqa_answer ||
                (answer.sub_questions &&
                  answer.sub_questions.some((subq) => subq.sqa_sub_answer))
            );

            this.showFormIcon = !hasAnyAnswer; // Show form if no answers exist
            this.question_with_answers = res.data.data;
          }
        }
      } catch (error) {
        console.error("Error in getting SOP answers", error);
      }
    },
    async submitAnswers() {
      try {
        const formattedAnswers = [];

        // Process main questions
        this.questions.forEach((question) => {
          if (this.answers[`main_${question.sq_id}`]) {
            formattedAnswers.push({
              sq_id: question.sq_id,
              sqa_answer: this.answers[`main_${question.sq_id}`],
            });
          }

          // Process subquestions
          if (question.subquestions && question.subquestions.length) {
            question.subquestions.forEach((subq) => {
              if (this.answers[`sub_${subq.ssq_id}`]) {
                formattedAnswers.push({
                  sq_id: question.sq_id,
                  ssq_id: subq.ssq_id,
                  sqa_sub_answer: this.answers[`sub_${subq.ssq_id}`],
                });
              }
            });
          }
        });
        // console.log("Answers",formattedAnswers)

        const res = await StudentServices.saveSOPAnswers({
          answers: formattedAnswers,
        });

        if (res.data.status) {
          this.$bvModal.hide("modal-questions");
          this.answers = {};
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Answers submitted successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getSOPAnswers();
        }
      } catch (error) {
        console.error("Error in submitting answers", error);
      }
    },
    getAnswer(sq_id) {
      const answerObj = this.answers.find((answer) => answer.sq_id === sq_id);
      return answerObj ? answerObj.sqa_answer : "";
    },
    updateAnswer(sq_id, sqa_answer) {
      const answerIndex = this.answers.findIndex(
        (answer) => answer.sq_id === sq_id
      );
      if (answerIndex !== -1) {
        this.answers[answerIndex].sqa_answer = sqa_answer;
      } else {
        this.answers.push({ sq_id, sqa_answer });
      }
    },

    async getSOPQuestions() {
      try {
        const res = await StudentServices.getSOPQuestions();
        if (res.data.status) {
          // Transform the questions data to properly handle subquestions
          this.questions = res.data.data.data;
        }
      } catch (error) {
        console.error("Error in getting SOP questions", error);
      }
    },
    generatePdfBlobUrl(binary) {
      const byteCharacters = atob(binary);
      const byteNumbers = Array.from(byteCharacters, (char) =>
        char.charCodeAt(0)
      );
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    },
    isFeatureCompleted(feature_id) {
      return this.completed_features.some(
        (feature) => feature.feature_id === feature_id
      );
    },
    getCompletedFeatureDate(feature_id) {
      const feature = this.completed_features.find(
        (f) => f.feature_id === feature_id
      );
      return feature ? feature.created_at : null;
    },
    moment,
    async getServicesAvailed() {
      try {
        const response = await StudentServices.getServicesAvailed();
        if (response.data.status) {
          this.services = response.data.data.map((s) => ({
            ...s,
            active_tab_index: 0,
          }));
        }
      } catch (err) {
        console.error("Error in getting student availed services:", err);
      }
    },
    async getCompletedFeatures() {
      try {
        const response = await StudentServices.getCompletedFeatures();
        if (response.data.status) {
          this.completed_features = response.data.data;
        }
      } catch (err) {
        console.error("Error in fetching completed features:", err);
      }
    },
  },
  beforeMount() {
    this.getServicesAvailed();
    this.getCompletedFeatures();
    this.getSOPAnswers();

    const params = new URLSearchParams(window.location.search);
    const servicesAvailed = params.get("services_availed") === "true";

    if (servicesAvailed) {
      this.services.forEach((service) => {
        if (service.plan_id === 6) {
          service.detailsShowing = true; // Automatically show details for plan_id 6
        }
      });
    }
  },
};
</script>

<style scoped></style>
